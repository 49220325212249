<template>
  <div class="store-gift-record-wrap" :class="getSearchCondition ? 'record-grid' : ''">
    <search-store-gift v-if="getSearchCondition"></search-store-gift>
    <store-gift-record-list></store-gift-record-list>
  </div>
</template>
<script>
import { defineAsyncComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
export default {
  name: 'manage-order',
  components: {
    StoreGiftRecordList: defineAsyncComponent(() => import('@/components/discount/StoreGiftRecordList.vue')),
    SearchStoreGift: defineAsyncComponent(() => import('@/components/discount/SearchStoreGift.vue')),
  },
  setup() {
    const route = useRoute();
    const getSearchCondition = useGetters('storeGiftRecord', ['getSearchCondition']);
    //門市資料
    const doGetStoreList = useActions('common', ['doGetStoreList']);
    doGetStoreList();
    //離開清空vuex
    const RESET = useMutations('storeGiftRecord', ['RESET']);
    watch(
      () => route.path,
      () => {
        if (!route.path.includes('/discount/record')) RESET();
      }
    );
    return { route, getSearchCondition };
  },
};
</script>
<style lang="scss" scoped>
.store-gift-record-wrap {
  width: 100%;
  height: 100%;
}
.record-grid {
  display: grid;
  grid-template-rows: 136px auto;
  grid-gap: 16px;
}
</style>
